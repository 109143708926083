<template>
  <v-content>
    <v-container fluid fill-height>
      <v-layout align-center justify-center column>
        <FileImage :file="getLogo()" class="logo-campaign" :useBg="true" v-if="getLogo()!=null" />
        <div class="logo-login" v-else></div>
        <Container maxWidth="570">
          <v-layout row wrap>
            <v-flex v-if="showContent" form-box text-xs-center pa-5>
              <div class="head-login">
                <h2>Email alterado com sucesso</h2>
              </div>

              <p class="py-5 f-size-16 text-gray-dark" v-if="$route.query.email">
                Seu novo e-mail para efetuar login é:
                <br />

                <strong class="text-default">{{ $route.query.email }}</strong>
                <!--email-->

                <br />
              </p>
              <p>
                <v-btn
                  flat
                  round
                  color="white"
                  href="/login"
                  class="btn-primary px-5"
                  large
                >EFETUAR LOGIN</v-btn>
              </p>
            </v-flex>
          </v-layout>
        </Container>

        <div class="login-copyright">
          <v-layout w-100>
            <v-flex>
              Powered by
              <a
                href="https://www.abraceumacausa.com.br"
                target="_blank"
                class="ma-0"
              >abraceumacausa.com.br</a>
            </v-flex>
            <v-flex text-xs-right>
              <a href>Termos de uso</a>
              <a href>Política de Privacidade</a>
            </v-flex>
          </v-layout>
        </div>
      </v-layout>
    </v-container>
    <FileImage :file="getBg()" :useBg="true" class="bg-campaign" v-if="getBg()!=null" />
    <div class="login-bg" v-else></div>
  </v-content>
</template>
<script type="plain/text">
import Container from "@/components/Container.vue";
import InputText from "@/components/input/InputText.vue";
import UserService from "@/scripts/services/user.service";
import CampaignService from "@/scripts/services/campaign.service";
import CampaignLandingPageConfig from "@/scripts/models/campaignLandingPageConfig.model";
import FileImage from "@/components/FileImage.vue";

export default {
  components: { Container, InputText, FileImage },
  data() {
    return {
      showContent: false,
      userService: new UserService(),
      campaignService: new CampaignService(),
      landingPageConfig: new CampaignLandingPageConfig()
    };
  },
  created() {
    if (this.$route.query.tempHash) {
      this.userService
        .confirmEmail(this.$route.query.tempHash, this.$route.query.email)
        .then(
          function(data) {
            if (data) this.showContent = true;
          }.bind(this)
        );
    }
    this.campaignService.getCampaignLandingPageConfigBySubdomain().then(
      function(data) {
        this.landingPageConfig = data;
      }.bind(this)
    );
  },
  methods: {
    getLogo() {
      if (
        this.landingPageConfig != null &&
        this.landingPageConfig.campaign != null &&
        this.landingPageConfig.campaign.file != null
      ) {
        return this.landingPageConfig.campaign.file;
      }
      return null;
    },
    getBg() {
      if (
        this.landingPageConfig != null &&
        this.landingPageConfig.heroFile != null
      ) {
        return this.landingPageConfig.heroFile;
      }
      return null;
    }
  }
};
</script>
